import React from 'react';
import { DynamicRollupsObject, DynamicRollupsRowConfig } from 'buyplan-common';
import cn from 'classnames';
import { getColumn } from '../../../helpers/tableTools';
import './DynamicRollupsCell.scss';

interface Props {
    columnKey: string;
    nextColumnKey: string;
    data: DynamicRollupsObject;
    columns: DynamicRollupsRowConfig[];
}

function DynamicRollupsCell({ columns, columnKey, data, nextColumnKey }: Props) {
    const column = getColumn(columns, columnKey);
    const nextColumn = nextColumnKey ? getColumn(columns, nextColumnKey) : ({} as DynamicRollupsRowConfig);
    const { key, getValue } = column;
    const { isGroupStart } = nextColumn;
    const rowValue = data[key as keyof DynamicRollupsObject] as number | null;
    const formattedValue = getValue ? getValue(rowValue, false) : rowValue;

    return (
        <div
            style={{ width: column.width }}
            className={cn('DynamicRollupsRow__cell', {
                DynamicRollupsRow__divider: isGroupStart,
            })}
        >
            <span
                key={key}
                className={cn('DynamicRollupsRow__text', {
                    'DynamicRollupsRow__text--negative': (rowValue ?? 0) < 0,
                })}
                title={String(formattedValue)}
            >
                {formattedValue || <em>N/A</em>}
            </span>
        </div>
    );
}

export default DynamicRollupsCell;
