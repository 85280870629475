import React, { CSSProperties } from 'react';
import { kebabCase } from 'lodash';
import { DynamicRollupsRowConfig, DynamicRollupsObject } from 'buyplan-common';
import DynamicRollupsCell from '../DynamicRollupsCell/DynamicRollupsCell';
import './DynamicRollupsRow.scss';

interface Props {
    row: DynamicRollupsObject;
    columns: DynamicRollupsRowConfig[];
    style?: CSSProperties;
}

function DynamicRollupsRow({ row, columns, style, ...rest }: Props) {
    return (
        <div style={style} className="DynamicRollupsRow" {...rest}>
            {columns.map(({ key, label }, index) => (
                <DynamicRollupsCell
                    key={`${key}-${kebabCase(label)}`}
                    columnKey={key}
                    nextColumnKey={columns[index + 1]?.key || ''}
                    data={row}
                    columns={columns}
                />
            ))}
        </div>
    );
}

export default DynamicRollupsRow;
