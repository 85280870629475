import { DynamicRollupsRowConfig, DynamicRollupsData, DynamicRollupsObject } from 'buyplan-common';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

type FormatterFunction = (value: string | number | null) => string | number | null;

// helper function to parse formatted values
const formatValues = (row: DynamicRollupsObject, formatters: Record<string, FormatterFunction>) => {
    const newRow = {} as DynamicRollupsObject;
    for (const [key, value] of Object.entries(row)) {
        newRow[key as keyof DynamicRollupsObject] = (formatters[key] ? formatters[key](value) : value) as number | null;
    }
    return newRow;
};

export const prepareDynamicRollupsExport = async (
    slicerColumns: DynamicRollupsRowConfig[],
    dynamicRollupsColumns: DynamicRollupsRowConfig[],
    data: DynamicRollupsData,
    filename: string
) => {
    const workbook = new Workbook();
    const sheet = workbook.addWorksheet('Sheet 1', {});
    const reportSubheaders: { key: string; header: string; width: number }[] = [];
    const reportGroupings: string[] = [];
    const mergedColumns = slicerColumns.concat(dynamicRollupsColumns);
    const formatters: Record<string, FormatterFunction> = {};

    mergedColumns.forEach((row) => {
        reportSubheaders.push({
            key: row.key,
            header: row.label,
            width: Math.max(row.label.length + 5, (row.groupStartHeader || '').length + 5),
        });

        if (row.groupStartHeader) {
            reportGroupings.push(row.groupStartHeader);
        } else if (row.groupKey) {
            reportGroupings.push(mergedColumns.filter((i) => i.key === row.groupKey)?.[0].groupStartHeader || '');
        }
        // creating the formatters reference object.
        formatters[row.key] = row.getValue;
    });
    sheet.columns = reportSubheaders;
    // Starging from the 4th row to give room for header and subheader.
    let currentRow = 4;
    data.objects.forEach((reportRow) => {
        // running the value formatter for every item in the row.
        sheet.getRow(currentRow).values = formatValues(reportRow, formatters);
        sheet.getRow(currentRow).font = { size: 14, name: 'Calibri' };
        currentRow++;
    });

    // Adding grouping to first row and totals to third row.
    sheet.getRow(2).values = sheet.getRow(1).values;
    sheet.getRow(1).values = reportGroupings;
    sheet.getRow(3).values = formatValues(data.totals, formatters);

    // styling grouping row
    const groupingRows = sheet.getRow(1);
    groupingRows.eachCell((_cell, col) => {
        groupingRows.getCell(col).font = { bold: true, size: 14, name: 'Calibri' };
    });

    // styling sub-header row
    const subheaderRow = sheet.getRow(2);
    subheaderRow.eachCell((_cell, col) => {
        subheaderRow.getCell(col).font = { bold: true, size: 14, name: 'Calibri' };
    });

    // styling totals row
    const totalsRow = sheet.getRow(3);
    totalsRow.eachCell((_cell, col) => {
        totalsRow.getCell(col).font = { bold: true, size: 14, name: 'Calibri' };
    });

    // merging grouping cells
    let initialIndex = 0;
    let currentGrouping = reportGroupings[initialIndex];
    for (let currentIndex = 0; currentIndex <= reportGroupings.length; currentIndex++) {
        const cur = reportGroupings[currentIndex];
        if (currentGrouping !== cur) {
            sheet.mergeCells(1, initialIndex + 1, 1, currentIndex);
            currentGrouping = cur;
            initialIndex = currentIndex;
        }
    }

    // merging headers and totals for slicerColumns columns
    sheet.mergeCells(2, 1, 2, slicerColumns.length);
    sheet.mergeCells(3, 1, 3, slicerColumns.length);
    sheet.getCell(2, 1).value = '';
    // exporting to file
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${filename}.xlsx`);
};
