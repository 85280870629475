import { DynamicRollupsData, DynamicRollupsMetaData, AsyncJobStatusEnum } from 'buyplan-common';
import { request, ResponseError } from '../api/api';

export const getMetaData = ({ seasonId, channelId }: { seasonId: number; channelId: number }) => {
    const queryParams = { seasonId, channelId };
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_ROLLUPS_ENDPOINT}/meta`,
        queryParams,
    };

    return request<{ data: DynamicRollupsMetaData }>(requestOptions);
};

type DynamicRollupsDataParams = {
    seasonId: number;
    channelId: number;
    slicers: string[];
    filters: {
        [key: string]: string[];
    };
};

const timeoutPromise = (time: number) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve(1);
        }, time);
    });

export const recursionGetDynamicRollupsJobById = async (jobId: string, eta: number): Promise<DynamicRollupsData> => {
    await timeoutPromise(eta * 1000);
    let res;
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_ROLLUPS_ENDPOINT}/dynamic-rollups/by-job-id/${jobId}`,
    };
    try {
        const { status, response } = await request<{
            status: AsyncJobStatusEnum;
            response: DynamicRollupsData & { errors?: string[] };
        }>(requestOptions);
        if (![AsyncJobStatusEnum.failed, AsyncJobStatusEnum.completed].includes(status)) {
            return await recursionGetDynamicRollupsJobById(jobId, eta);
        }
        if (status === AsyncJobStatusEnum.failed) {
            throw new Error(JSON.stringify(response.errors));
        }

        return response;
    } catch (err: unknown) {
        const er = err as ResponseError;
        if (er?.response?.status === 502 || er?.message === 'Failed to fetch') {
            await timeoutPromise(5000);
            res = await recursionGetDynamicRollupsJobById(jobId, eta);
            return res;
        }
        throw err;
    }
};

export const getDynamicRollups: (params: DynamicRollupsDataParams) => Promise<DynamicRollupsData> = async ({
    seasonId,
    channelId,
    slicers,
    filters,
}) => {
    const filtersQueryParams = Object.entries(filters)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, values]) => values.length > 0)
        .map(([key, values]) => {
            const filterValues = values.filter((v) => v.length > 0);
            return filterValues.length > 0 ? `${key}(${filterValues.join(',')})` : '';
        });

    const queryParams = { seasonId, channelId, slicers: slicers.join(','), filters: filtersQueryParams };
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_ROLLUPS_ENDPOINT}/dynamic-rollups`,
        queryParams,
    };

    const { jobId, eta } = await request<{ jobId: string; eta: number }>(requestOptions);
    return recursionGetDynamicRollupsJobById(jobId, eta);
};
