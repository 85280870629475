import { DynamicRollupsData, LoadingStatus } from 'buyplan-common';
import {
    CLEAR_DYNAMIC_ROLLUPS_DATA,
    GET_DYNAMIC_ROLLUPS,
    GET_DYNAMIC_ROLLUPS_FAILURE,
    GET_DYNAMIC_ROLLUPS_SUCCESS,
} from '../actions/actionTypes';
import { DynamicRollupsActions } from '../actions/dynamicRollups';

export interface DynamicRollupsState {
    data: DynamicRollupsData;
    loadingStatus?: LoadingStatus;
    error?: Error;
}

export const initialState: DynamicRollupsState = {
    data: {} as DynamicRollupsData,
    loadingStatus: LoadingStatus.initial,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state: DynamicRollupsState = initialState, action: DynamicRollupsActions): DynamicRollupsState => {
    switch (action.type) {
        case GET_DYNAMIC_ROLLUPS: {
            return {
                ...state,
                data: {} as DynamicRollupsData,
                loadingStatus: LoadingStatus.loading,
            };
        }
        case GET_DYNAMIC_ROLLUPS_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                data,
                loadingStatus: LoadingStatus.success,
            };
        }
        case GET_DYNAMIC_ROLLUPS_FAILURE: {
            const { error } = action;
            return {
                ...state,
                data: {} as DynamicRollupsData,
                error,
                loadingStatus: LoadingStatus.error,
            };
        }
        case CLEAR_DYNAMIC_ROLLUPS_DATA: {
            return {
                ...state,
                data: {} as DynamicRollupsData,
                loadingStatus: LoadingStatus.initial,
            };
        }
        default:
            return state;
    }
};
